import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchProfileDetails({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get("/api/v1/user/profile-details/");

        commit("saveUser", JSON.stringify(response.data));
        commit("SET_USER_DATA");
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  fetchResumeDetails({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get("/api/v1/resume/");
       //console.log(response.data)
        // commit("saveUser", JSON.stringify(response.data));
        // commit("SET_USER_DATA");
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  updateProfileDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        // const response = await axios.patch("/api/v1/profile/", formData);
        const response = await axios.post("/api/v1/user/profile-details/", formData);

        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateResumeDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        // const response = await axios.patch("/api/v1/profile/", formData);
        const response = await axios.post("/api/v1/resume/", formData);

        dispatch("fetchResumeDetails");
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateProfilePassword({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/set_or_reset_password/",
          formData
        );

        commit("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const profileStore: Module<any, any> = {
  state: () => ({}),
  mutations: {
    saveUser(state, user) {
      localStorage.setItem("user", user);
    },
  },
  actions,
  getters: {},
};

export default profileStore;
