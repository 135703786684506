import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getDistrictReports({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      if(data.update != false) {
        commit("resetData");
      }
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/data/district-report/`, { params: data });
        if(data.update != false) {
          commit("setDistrictReportData", response.data);
        }
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  }
};
const adminReportsStore: Module<any, any> = {
  state: () => ({
    districtsReports: [],
  }),
  mutations: {
    resetData(state) {
      state.districtsReports = [];
    },
    setDistrictReportData(state, data) {
        state.districtsReports = data.data;
    }
  },
  actions,
  getters: {
    districtsReports(state) {
        return state.districtsReports;
    }
  },
};

export default adminReportsStore;
