import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getInvitationDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/registration/invitation-${data.invitationId}/`);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  submitCollegePayment({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/registration/payment-confirmation/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  performPreCollegeRegister({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/registration/pre_registration/`, formData);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  performPreCollegeDataVerifyRegister({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/registration/pre_registration_verification/`, formData);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  performCollegeRegister({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/registration/registration_details/`, formData);
        commit("SET_LOADING", false);
        commit("SET_LOGGED_IN", true);
        const json = response.data;
        commit("saveToken", json.login_details.access);
        commit("saveRefreshToken", json.login_details.refresh);
        // commit("saveUser", JSON.stringify(json));
        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sendStudentInvite({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/student/invite/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  downloadStudentsList({ commit, dispatch }, data: any) {
    dispatch("getStudentsList", data).then((response) => {
      let csvJSON = []
      for (let i = 0; i < response.students_list.length; i++) {
        csvJSON.push({
          'S No': i + 1,
          'Username': response.students_list[i].username,
          'Full Name': response.students_list[i].full_name,
          'Hall Ticket Number': response.students_list[i].hall_ticket_number,
          'Email': response.students_list[i].email,
           "Year of Registration": response.students_list[i].created,
          // 'Phone Number': response.students_list[i].phone_number,
          'Specialization': response.students_list[i].specialization,
          "Year of Graduation": response.students_list[i].year_of_graduation,
          "College Id": response.students_list[i].college_id,
          "College": response.students_list[i].college,
          "District": response.students_list[i].district,
        })
      }

      dispatch('downloadJSONToCSV', {data: csvJSON, filename: 'students_list.csv'});
    })
  },
  downloadCollegeStudentsList({ commit, dispatch }, data: any) {
    dispatch("getStudentsList", data).then((response) => {
      let csvJSON = []
      for (let i = 0; i < response.students_list.length; i++) {
        csvJSON.push({
          'S No': i + 1,
          'Username': response.students_list[i].username,
          'Full Name': response.students_list[i].full_name,
          'Hall Ticket Number': response.students_list[i].hall_ticket_number,
          "College": response.students_list[i].college,
          'Branch': response.students_list[i].specialization,
          "Year of Graduation": response.students_list[i].year_of_graduation,
        })
      }

      dispatch('downloadJSONToCSV', {data: csvJSON, filename: 'students_list.csv'});
    })
  },
  getStudentsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetStudentsData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/student/list/`, { params: data });
        commit("setStudentsListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getStudentsDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/student/details/${data.studentId}`);
        commit("setStudentsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  approveRejectStudentCertificate({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/student/details/${data.id}/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  sendStudentsBulkInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/student/invite/bulk/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  collegeEditProfile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/edit_college/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const collegeStore: Module<any, any> = {
  state: () => ({
    students: [],
    student: {},
    studentTotalPages: 0,
    studentsCount: 0,
    limit: 20,
    page: 0,
    yearOfStudyFilters: [],
  }),
  mutations: {
    setStudentsData(state, data) {
      state.student = data;
    },
    resetStudentsData(state) {
      state.students = [];
      state.yearOfStudyFilters = [];
      state.studentsCount = 0
      state.studentTotalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setStudentsListData(state, data) {
      state.students = data.students_list;
      state.yearOfStudyFilters = data.filters.years;
      state.studentsCount = data.total_count
      state.studentTotalPages = Math.ceil(data.total_count / data.limit);
      state.limit = data.limit;
      state.page = data.page;
    }
  },
  actions,
  getters: {
    collegeStudentsList(state) {
      return state.students;
    },
    collegeStudentsCount(state) {
      return state.studentsCount;
    },
    studentsYearOfStudyFilters(state) {
        return state.yearOfStudyFilters;
    },
    studentTotalPages(state) {
      return state.studentTotalPages;
    },
    studentPage(state) {
      return state.page;
    },
    collegeStudent(state) {
      return state.student;
    }
  },
};

export default collegeStore;
