import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { baseUrl } from "./global_urls";
import http from "@/http/http";

// const baseUrl = "http://escalapp.illuminates.xyz:8001";
const config: AxiosRequestConfig = {
  baseURL: baseUrl,
};

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    };
  }

  return config;
};

const auth = axios.create(config);

auth.interceptors.request.use(onRequest);

export default auth;
