import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import moment from "moment";

const actions: ActionTree<any, any> = {
  getAnnouncedCoursesData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/workshops/announced/`, formData);
        commit('setCollegeAdminCoursesData', response.data.announced_workshops);
        commit('setCollegeAdminCoursesCategoriesData', response.data.announced_workshops_categories);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  downloadWorkShopsList({ commit, dispatch }, data: any) {
    dispatch("getWorkShopsList", data).then((response) => {
      let csvJSON = []
      for (let i = 0; i < response.data.length; i++) {
        csvJSON.push({
          'S No': i + 1,
          'Collete Name': response.data[i].college_name,
          'Course Name': response.data[i].announced_workshop_name,
          'Request On': moment(response.data[i].created).format("DD-MM-YYYY h:mm a"),
          'Start Date': moment(response.data[i].start_date).format("DD-MM-YYYY"),
          'End Date': moment(response.data[i].end_date).format("DD-MM-YYYY"),
          'Batch Size': response.data[i].total_students,
          'District': response.data[i].district_name,
        })
      }

      dispatch('downloadJSONToCSV', {data: csvJSON, filename: 'courses_list.csv'});
    })
  },
  getWorkShopsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/workshops/workshops/`, { params: data });
        commit('setCollegeAdminWorkshopsData', response.data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getTrainerWorkShopsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/workshops/trainer_availability_list/`, { params: data });
        commit('setCollegeAdminWorkshopsData', response.data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getPublicWorkShopsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/workshops/public_workshops/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  createWorkshopRequest({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
  
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
  
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/workshops/request/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          if (err.response && err.response.data && err.response.data.message) {
            reject(err.response.data.message);
          } else {
            reject(err.message);
          }
        } catch (e: any) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },  
};
const collegeCoursesStore: Module<any, any> = {
  state: () => ({
    courses: [],
    coursesCategories: {},
    workshops: [],
  }),
  mutations: {
    setCollegeAdminWorkshopsData(state, data) {
      state.workshops = data;
    },
    setCollegeAdminCoursesData(state, data) {
      state.courses = data;
    },
    setCollegeAdminCoursesCategoriesData(state, data) {
      state.coursesCategories = data;
    },
    resetCoursesData(state) {
      state.courses = [];
      state.coursesCategories = [];
    },
  },
  actions,
  getters: {
    requestedWorkshops(state) {
      return state.workshops;
    },
    announcedCourses(state) {
      return state.courses;
    },
    announcedCoursesCategories(state) {
      return state.coursesCategories;
    },
  },
};

export default collegeCoursesStore;
