import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    name: "App",
    component: () =>
        import(/* webpackChunkName: "base" */ "../layouts/NavBarSideLayout.vue"),
    children: [
      {
        path: "/",
        name: 'Home',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "base" */ '../views/CheckingPage.vue'),
      },
      // Admin Endpoints Start
      // {
      //   path: '/admin/colleges',
      //   redirect: '/admin/colleges/invite',
      //   meta: { requiresAuth: true },
      // },
      // {
      //   path: '/admin/colleges',
      //   redirect: '/admin/colleges/invite',
      //   meta: { requiresAuth: true },
      // },
      // {
      //   path: '/admin/colleges/invite',
      //   name: 'AdminColleges',
      //   meta: { requiresAuth: true },
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminColleges.vue')
      // },
      {
        path: '/admin/colleges/upload',
        name: 'AdminCollegesCSVUpload',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/InviteCollegesUploadPage.vue')
      },
      {
        path: '/admin/colleges/invited',
        name: 'AdminInvitedColleges',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminInvitedColleges.vue')
      },
      {
        path: '/admin/colleges/',
        name: 'AdminColleges',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminRegisteredColleges.vue')
      },
      {
        path: '/admin/college/:collegeId',
        name: 'AdminCollegeDetails',
        props: true,
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminCollegeDetails.vue')
      },
      {
        path: '/admin/students',
        name: 'AdminStudents',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/students/AdminRegisteredStudents.vue')
      },
      {
        path: '/admin/reports/district',
        name: 'DistrictWiseReportPage',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/reports/DistrictWiseReportPage.vue')
      },
      {
        path: '/admin/student/bulk',
        name: 'AdminBulkUploadStudents',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/students/AdminBulkUploadStudents.vue')
      },
      {
        path: '/admin/student/:studentId',
        name: 'AdminStudentDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminStudentDetailsPage.vue')
      },
      {
        path: '/admin/workshops/',
        name: 'AdminCourses',
        meta: { requiresAuth: true, role: "admin,coordinator", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/workshops/AdminCoursesListPage.vue')
      },
      {
        path: '/admin/course/approve/',
        name: 'AdminCourseApprovePage',
        props: true,
        meta: { requiresAuth: true, role: "admin,coordinator", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/workshops/AdminCourseApprovePage.vue')
      },
      {
        path: '/admin/workshop-titles/',
        name: 'WorkshopTitlesPage',
        props: true,
        meta: { requiresAuth: true, role: "admin,coordinator", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/announced-workshops/WorkshopTitlesPage.vue')
      },
      {
        path: '/admin/course/',
        name: 'AdminCourseDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "admin,coordinator", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/workshops/AdminCourseDetailsPage.vue')
      },
      {
        path: '/admin/calendar/',
        name: 'AdminCalendar',
        meta: { requiresAuth: true, role: "admin,coordinator", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/calendar/AdminCalendarPage.vue')
      },
      {
        path: '/admin/trainer-calendar/',
        name: 'AdminTrainerCalendarPage',
        meta: { requiresAuth: true, role: "admin,coordinator", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/calendar/AdminTrainerCalendarPage.vue')
      },
      {
        path: '/admin/special-courses/',
        name: 'SpecialCoursesListPage',
        meta: { requiresAuth: true, role: "admin,coordinator,placements", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/special-courses/SpecialCoursesListPage.vue')
      },
      {
        path: '/admin/special-courses/add/',
        name: 'AddSpecialCoursePage',
        meta: { requiresAuth: true, role: "admin,coordinator,placements", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/special-courses/AddSpecialCoursePage.vue')
      },
      {
        path: '/admin/special-courses/edit/:courseId',
        name: 'EditSpecialCoursePage',
        props: true,
        meta: { requiresAuth: true, role: "admin,coordinator,placements", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/special-courses/AddSpecialCoursePage.vue')
      },
      // Admin Endpoints End
        // Coordinator Endpoints Start

        // Coordinator Endpoints End
      //  College Endpoints Start
      {
        path: '/college/renewal/check',
        name: 'CollegeRenewalCheckPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/payment/CollegeRenewalCheckPage.vue')
      },
      {
        path: '/college/profile',
        name: 'CollegeProfilePage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/profile/CollegeProfilePage.vue')
      },
      {
        path: '/college/profile/edit',
        name: 'CollegeProfileEditPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/profile/CollegeProfileEditPage.vue')
      },
      {
        path: '/college/workshops',
        name: 'CoursesListPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/courses/CoursesListPage.vue')
      },
      {
        path: '/college/workshop-titles/',
        name: 'CollegeWorkshopTitlesPage',
        props: true,
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/announced-workshops/WorkshopTitlesPage.vue')
      },
      {
        path: '/college/course',
        name: 'CourseDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "college,student", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/courses/CourseDetailsPage.vue')
      },
      {
        path: '/college/request/course',
        name: 'RequestCoursePage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/courses/RequestCoursePage.vue')
      },
      {
        path: '/college/students/add',
        name: 'CollegeStudentsAdd',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/AddStudentFormPage.vue')
      },
      {
        path: '/college/students/list',
        name: 'CollegeStudentsList',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/StudentsListPage.vue')
      },
      {
        path: '/college/student/:studentId',
        name: 'CollegeStudentDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminStudentDetailsPage.vue')
      },
      {
        path: '/college/students/validate',
        name: 'StudentValidateCasteCertificate.vue',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/StudentValidateCasteCertificate.vue')
      },
      {
        path: '/college/students/upload',
        name: 'InviteStudentsUploadPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/invite/InviteStudentsUploadPage.vue')
      },
      {
        path: '/college/calendar/',
        name: 'CollegeCalendar',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/college/calendar/CollegeCalendarPage.vue')
      },
    //    College Endpoints End
    //    Student Endpoints Start
      {
        path: '/students/home',
        name: 'StudentsHomePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/StudentsHomePage.vue')
      },
      {
        path: '/students/payment/details',
        name: 'StudentPaymentDetailsPage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentRenewalCheckPage.vue')
      },
      {
        path: '/student/workshop-titles/',
        name: 'StudentWorkshopTitlesPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/announced-workshops/WorkshopTitlesPage.vue')
      },
      {
        path: '/jobs',
        name: 'StudentJobsHomePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/jobs/StudentJobsHomePage.vue')
      },
      {
        path: '/jobs/:sectorId',
        name: 'StudentJobSectorCompaniesPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/jobs/StudentJobSectorCompaniesPage.vue')
      },
      {
        path: '/jobs/:sectorId/:companyId',
        name: 'StudentCompanyJobsPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/jobs/StudentCompanyJobsPage.vue')
      },
      {
        path: '/workshops',
        name: 'StudentCourseListPage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentCourseListPage.vue')
      },
      {
        path: '/workshops/my',
        name: 'StudentMyCourseListPage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentMyCourseListPage.vue')
      },
      {
        path: '/course/:courseId',
        name: 'StudentCourseDetailPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentCourseDetailPage.vue')
      },
      {
        path: '/workshops/completed',
        name: 'StudentCompletedCourseListPage',
        props: true,
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/courses/StudentCompletedCourseListPage.vue')
      },
      {
        path: '/s/profile',
        name: 'StudentProfilePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/profile/StudentProfilePage.vue')
      },
      {
        path: '/s/profile/edit',
        name: 'EditProfilePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/edit/EditProfilePage.vue')
      },
      {
        path: '/student/calendar/',
        name: 'StudentCalendar',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/college/calendar/CollegeCalendarPage.vue')
      },
      {
        path: '/student/placements/',
        name: 'StudentPlacements',
        meta: { requiresAuth: true, role: "student,college,admin,placements,da,coordinator", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/placements/StudentPlacementsListPage.vue')
      },
      {
        path: '/student/events/',
        name: 'EventsSpecialCoursesListPage',
        meta: { requiresAuth: true, role: "student,college,admin", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/special-courses/SpecialCoursesListPage.vue')
      },
    //    Student Endpoints End
    //    Course Endpoints Start

    //    Courses Endpoints End
      //    RM CM Endpoints Start
      {
        path: '/da/districts/',
        name: 'RMCMDistrictsList',
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/RMCMDistrictsList.vue')
      },
      {
        path: '/da/colleges/',
        name: 'RMCMColleges',
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/colleges/RMCMColleges.vue')
      },
      {
        path: '/da/college/details/',
        name: 'RMCMCollegeDetails',
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/colleges/RMCMCollegeDetails.vue')
      },
      {
        path: '/da/students',
        name: 'RMCMStudents',
        props: true,
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/students/RMCMStudents.vue')
      },
      {
        path: '/da/student/details',
        name: 'RMCMStudentDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/students/RMCMStudentDetailsPage.vue')
      },
      {
        path: '/da/courses',
        name: 'RMCMWorkshopsPage',
        props: true,
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/workshops/RMCMWorkshopsPage.vue')
      },
      {
        path: '/da/course/details',
        name: 'RMCMWorkshopDetailsPage',
        props: true,
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/workshops/RMCMWorkshopDetailsPage.vue')
      },
      {
        path: '/da/calendar',
        name: 'RMCMCalendarPage',
        props: true,
        meta: { requiresAuth: true, role: "da", },
        component: () => import(/* webpackChunkName: "da" */ '../views/rmcm/workshops/RMCMCalendarPage.vue')
      },
      //    RM CM Endpoints End
      //    Placements Endpoints Start
      {
        path: '/placements',
        name: 'PlacementsListPage',
        meta: { requiresAuth: true, role: "placements,da,college,admin,coordinator", },
        component: () => import(/* webpackChunkName: "placements" */ '../views/placements/PlacementsListPage.vue')
      },
      {
        path: '/placement/add',
        name: 'AddPlacementPage',
        meta: { requiresAuth: true, role: "placements", },
        component: () => import(/* webpackChunkName: "placements" */ '../views/placements/add/AddPlacementPage.vue')
      },
      {
        path: '/placement/edit/:placementId',
        name: 'EditPlacementPage',
        props: true,
        meta: { requiresAuth: true, role: "placements", },
        component: () => import(/* webpackChunkName: "placements" */ '../views/placements/add/AddPlacementPage.vue')
      },
      {
        path: '/placements/applied',
        name: 'AppliedPlacementsListPage',
        meta: { requiresAuth: true, role: "placements", },
        component: () => import(/* webpackChunkName: "placements" */ '../views/placements/AppliedPlacementsListPage.vue')
      },
      //    Placements Endpoints End
    ],
  },
  {
    path: "/",
    name: "NoAuth",
    component: () =>
        import(/* webpackChunkName: "base" */ "../layouts/NoNavBarLayout.vue"),
    children: [
      // {
      //   path: "",
      //   redirect: "/login",
      // },
      //  College Endpoints Start
      {
        path: '/mobile/autologin',
        name: 'MobileAutoLogin',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "base" */ '../views/auth/MobileTokenSet.vue')
      },
      {
        path: '/college/payment/status',
        name: 'CollegePaymentSuccessPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/PaymentSuccessPage.vue')
      },
      {
        path: '/college/pending',
        name: 'CollegePaymentVerificationPending',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/payment/CollegePaymentVerificationPending.vue')
      },
      {
        path: '/college/renewal',
        name: 'CollegeRenewalPage',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/CollegeRenewalPage.vue')
      },
      {
        path: '/college-registration/',
        name: 'CollegeRegistration',
        props: true,
        component: () => import(/* webpackChunkName: "college" */ '../views/college/CollegeRegistration.vue')
      },
      // {
      //   path: '/college-registration/:invitationId',
      //   name: 'CollegeRegistration',
      //   props: true,
      //   component: () => import(/* webpackChunkName: "college" */ '../views/college/CollegeRegistration.vue')
      // },
      {
        path: '/college/payment',
        name: 'CollegePayment',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/CollegePaymentPage.vue')
      },
      {
        path: '/college/payment-success',
        name: 'CollegePaymentSuccess',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/PaymentSuccessPage.vue')
      },
      {
        path: '/college/payment-failed',
        name: 'CollegePaymentFailed',
        meta: { requiresAuth: true, role: "college", },
        component: () => import(/* webpackChunkName: "college" */ '../views/college/PaymentFailedPage.vue')
      },
      //  College Endpoints End
      //  Student Endpoints Start
      {
        path: '/student-registration/',
        name: 'StudentRegistration',
        props: true,
        meta: { requiresAuth: false, },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/StudentRegistration.vue')
      },
      {
        path: '/regional/student-registration/',
        name: 'RegionalStudentRegistration',
        props: true,
        meta: { requiresAuth: false, },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/StudentRegistration.vue')
      },
      // {
      //   path: '/student-registration/:invitationId',
      //   name: 'StudentRegistration',
      //   props: true,
      //   component: () => import(/* webpackChunkName: "student" */ '../views/student/StudentRegistration.vue')
      // },
      {
        path: '/student/payment/redirect',
        name: 'StudentRedirectPaymentPage',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentRedirectPaymentPage.vue')
      },
      {
        path: '/student/payment/',
        name: 'StudentPayment',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentPaymentPage.vue')
      },
      {
        path: '/student/first/edit',
        name: 'FirstEditStudent',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/edit/FirstEditStudent.vue')
      },
      {
        path: '/student/profile/fill',
        name: 'FillStudentProfilePage',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/edit/EditProfilePage.vue')
      },
      {
        path: '/student/payment/status',
        name: 'StudentPaymentSuccess',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentPaymentSuccessPage.vue')
      },
      {
        path: '/student/payment-failed',
        name: 'StudentPaymentFailed',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/payment/StudentPaymentFailedPage.vue')
      },
      {
        path: '/student/resume-builder',
        name: 'ResumeBuilder',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/ResumeBuilder.vue')
      },
      {
        path: '/student/resume-print',
        name: 'ResumePrint',
        meta: { requiresAuth: true, role: "student", },
        component: () => import(/* webpackChunkName: "student" */ '../views/student/ResumePrint.vue')
      },
      //  Student Endpoints End
      {
        path: '/login',
        name: 'Login',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginPage.vue')
      },
      {
        path: '/forgot-username',
        name: 'ForgotUsername',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/ForgotUsernamePage.vue')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/ForgotPasswordPage.vue')
      },
        // Public Endpoints
      {
        path: '/public/calendar',
        name: 'PublicCalendarPage',
        component: () => import(/* webpackChunkName: "public" */ '../views/public/PublicCalendarPage.vue')
      },
      {
        path: '/public/announcements',
        name: 'PublicAnnouncementsPage',
        component: () => import(/* webpackChunkName: "public" */ '../views/public/PublicAnnouncementsPage.vue')
      },
      {
        path: '/public/placements',
        name: 'PublicPlacementsListPage',
        component: () => import(/* webpackChunkName: "public" */ '../views/public/PublicPlacementsListPage.vue')
      },
      {
        path: '/public/dynamic/payment/start',
        name: 'PublicDynamicPaymentForm',
        component: () => import(/* webpackChunkName: "public" */ '../views/public/PublicDynamicPaymentForm.vue')
      },
      {
        path: '/public/dynamic/payment/confirmation/:course_id/:course_name/:payment/:gst/:name/:mobile/:email/:message/:transaction_id/:enc/:encmsg',
        name: 'PublicPaymentConfirmation',
        component: () => import(/* webpackChunkName: "public" */ '../views/public/PublicPaymentConfirmation.vue')
      },
      {
        path: '/public/dynamic/payment/success/:transaction_id/:course_name/:name/:mobile/:email/:payment_datetime/:amount',
        name: 'PublicPaymentSuccessPage',
        component: () => import(/* webpackChunkName: "public" */ '../views/public/PublicPaymentSuccessPage.vue')
      },
      {
        path: '/public/dynamic/payment/failed/:transaction_id/:course_name/:name/:mobile/:email/:payment_datetime/:amount',
        name: 'PublicPaymentFailedPage',
        component: () => import(/* webpackChunkName: "public" */ '../views/public/PublicPaymentFailedPage.vue')
      },
    ],
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.meta.requiresAuth);
  // console.log(store.state.isLoggedIn);
  if (to.meta.requiresAuth && !store.state.isLoggedIn) next({ name: "Login" });
  else if (to.meta.isLoginPage && store.state.isLoggedIn)
    next({ name: "Home" });
  else {
    let metaRole = to.meta.role;
    if(!metaRole) {
      next();
    } else {
      // @ts-ignore
      const role = store.state.activeUser["account_role"];
      let roleCheck = "";
      if (role === 1 || role === 2 || role === 4 || role === 5) {
        roleCheck = "admin";
      }
      if (role === 3) {
        roleCheck = "coordinator";
      }
      if (role === 6 || role === 7) {
        roleCheck = "college";
      }
      if (role === 8) {
        roleCheck = "student"
      }
      if (role === 10) {
        roleCheck = "da"
      }
      if (role === 11) {
        roleCheck = "placements"
      }
      // @ts-ignore
      if (metaRole.indexOf(roleCheck) === -1) {
        next({name: "Home"});
      } else {
        next();
      }
    }
  }
});
export default router
