import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store/index";
import { baseUrl } from "./global_urls";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    };
  }

  return config;
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  try {
    if (error?.response?.status == 401) {
      store.dispatch("logout");
    }
  } catch (e) {
    return Promise.reject(error);
  }
  return Promise.reject(error);
};
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  try {
    if (error?.response?.status == 401) {
      await store.dispatch("tokenRefresh");
      const config = error.config;
      // @ts-ignore
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      return http.request(config);
    }
  } catch (e) {
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

const config: AxiosRequestConfig = {
  baseURL: baseUrl,
};

const http = axios.create(config);

http.interceptors.request.use(onRequest, onRequestError);

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

http.interceptors.response.use(onResponse, onResponseError);

export default http;
