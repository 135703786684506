import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    performStudentRegister({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data.studentData).forEach((key: string) => {
                formData.append(key, data.studentData[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/register/`, formData);
                commit("SET_LOADING", false);
                commit("SET_LOGGED_IN", true);
                const json = response.data;
                commit("saveToken", json.access);
                commit("saveRefreshToken", json.refresh);
                // commit("saveUser", JSON.stringify(json));
                dispatch("fetchProfileDetails");
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    performStudentFirstEdit({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data.studentData).forEach((key: string) => {
                formData.append(key, data.studentData[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/edit-details/`, formData);
                const json = response.data;
                dispatch("fetchProfileDetails");
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    performResumeEdit({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data.studentData).forEach((key: string) => {
                formData.append(key, data.studentData[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/resume/`, formData);
                const json = response.data;
                dispatch("fetchResumeDetails");
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    submitStudentPayment({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/registration/payment-confirmation/`, formData);

                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getStudentRegistrationDropdown({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/data/student-registration-dropdowns/`);
                commit('setCasteData', response.data.caste);
                commit('setGenderData', response.data.gender);
                commit('setDegreeData', response.data.degree);
                commit('setSkillLevelData', ['Beginner', 'Intermediate', 'Expert']);
                commit('setProgrammingSkillLevelData', ['Beginner', 'Intermediate', 'Expert']);
                commit('setLanguageSkillLevelData', ['Elementary Proficiency', 'Limited Working Proficiency', 'Professional Working Proficiency','Full Professional Proficiency','Native / Bilingual Proficiency']);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getCollegesForStudentsRegistration({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            // commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/data/college-list/`, { params: data });
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            // commit("SET_LOADING", false);
        });
    },

    performPreStudentRegister({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/pre_registration/`, formData);
                resolve(response.data);
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    performPreStudentDataVerifyRegister({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/pre_registration_verification/`, formData);
                resolve(response.data);
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
};
const studentStore: Module<any, any> = {
    state: () => ({
        casteData: {},
        genderData: {},
        degreeData: {},
        skillLevelData: {},
        programmingSkillLevelData: {},
        languageSkillLevelData: {},
    }),
    mutations: {
        setCasteData(state, data: any) {
            state.casteData = data;
        },
        setGenderData(state, data: any) {
            state.genderData = data;
        },
        setDegreeData(state, data: any) {
            state.degreeData = data;
        },
        setSkillLevelData(state, data: any) {
            state.skillLevelData = data;
        },
        setProgrammingSkillLevelData(state, data: any) {
            state.programmingSkillLevelData = data;
        },
        setLanguageSkillLevelData(state, data: any) {
            state.languageSkillLevelData = data;
        },
    },
    actions,
    getters: {
        casteData(state) {
            return state.casteData;
        },
        genderData(state) {
            return state.genderData;
        },
        degreeData(state) {
            return state.degreeData;
        },
        skillLevelData(state) {
            return state.skillLevelData;
        },
        programmingSkillLevelData(state) {
            return state.programmingSkillLevelData;
        },
        languageSkillLevelData(state) {
            return state.languageSkillLevelData;
        },
    },
};

export default studentStore;
