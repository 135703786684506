import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getRMDistrictsData({commit, dispatch}) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      if (localStorage.getItem('rm-districts')) {
        // @ts-ignore
        commit('saveRMDistricts', JSON.parse(localStorage.getItem('rm-districts')));
      } else {
        try {
          const response = await axios.get(`/api/v1/data/auth_districts/`);
          commit('saveRMDistricts', response.data);
          resolve(response.data);
        } catch (err: any) {
          try {
            reject(err.response.data.message);
          } catch (e) {
            reject(err.message);
          }
        }
      }
      commit("SET_LOADING", false);
    });
  },
  loadDistrict({commit}) {
    let district = localStorage.getItem("da-district");
    commit("setDistrict", district);
  }
};
const rmcmStore: Module<any, any> = {
  state: () => ({
    district: '',
    rmDistricts: [],
  }),
  mutations: {
    setDistrict(state, data) {
      localStorage.setItem("da-district", data);
      if(data == 'null') {
        state.district = '';
      } else {
        state.district = data;
      }
    },
    saveRMDistricts(state, data: any) {
      localStorage.setItem("rm-districts", JSON.stringify(data));
      state.rmDistricts = data;
    },
  },
  actions,
  getters: {
    district(state) {
        return state.district;
    },
    rmDistricts(state) {
        return state.rmDistricts;
    },
  },
};

export default rmcmStore;
