import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    sendOTPUpdateEmailPhone({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/update_student_email_mobile/`, formData);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    verifyUpdateEmailPhone({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/student/verify_student_email_mobile/`, formData);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
};
const commonUpdateStore: Module<any, any> = {
    state: () => ({
    }),
    mutations: {
    },
    actions,
    getters: {
    },
};

export default commonUpdateStore;
